<template>
  <div class="wrap">
    <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.banner + ')'}">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">病区24H心电监护中心</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details" style="margin-bottom: 118px">
          <div class="text bg arrow-right one">
            <p>心电监护仪是医院实用的精密医学仪器，能同时监护病人的动态实用的精密医学仪器。该设备具有心电信息的采集、存储、智能分析预警等功能，并具备精准监测、触屏操控、简单便捷等特点。</p>
            <p style="text-indent: 0">①、心率（一般在最上面，正常值60—100，单位是：次/分）;</p>
            <p style="text-indent: 0">②、血压（包括收缩压和舒张压，收缩压正常值为zhidao90-140，舒张压正常值为50-90，单位为mmHg，如120/80mmHg就是正常血压）;</p>
            <p style="text-indent: 0">③、血氧饱和度（正常值90—100，一般人都是99-100，越缺氧越低）；</p>
            <p style="text-indent: 0">④、呼吸：正常值16-22次/分；</p>
            <p style="text-indent: 0">⑤、体温，正常值小于37.3℃，大于37.3℃就表示发烧了；</p>
          </div>
          <div class="img ecg1">
            <img :src="imgUrl.img1"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="content bg">
      <div class="inner product">
        <div class="details">
          <div class="img ecg"><img :src="imgUrl.img2"
               alt=""></div>
          <div class="text text2">
            <p>病区24H心电监护中心以病区中央监护中心主电脑和病区中央生命体征监护中心主电脑为执行核心，床旁24小时无扰式监控终端为床旁执行与采集系统，实现对全病区24小时自动监护与监控。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'EcgMonitor',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/ecg-banner.png')
    this.imgUrl.img1 = getAliyunImg.signatureUrl('ytwd/gw/ecg-img2.png')
    this.imgUrl.img2 = getAliyunImg.signatureUrl('ytwd/gw/ecg-img.png')
  },
  data () {
    return {
      imgUrl: {
        banner: '',
        img1: '',
        img2: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  background-image: url("../../assets/images/ecg-banner.png");
  .icon {
    background-image: url("../../assets/images/ecg-icon.png");
  }
}
.content {
  &.bg {
    background-color: #EDFFFE;
    padding: 50px 0 100px;
  }
  .product {
    .details {
      margin-bottom: 45px;
      .one {
        margin-left: 70px;
        &.bg {
          background-size: 210px 207px;
        }
      }
      .text2 {
        width: 546px;
        padding: 20px 35px;
        background: #fff;
        font-size: 16px;
        line-height: 36px;
        color: #6a6a6a;
        margin: 0 70px 0 0;
        box-shadow: 1px 1px 24px 0px rgba(0, 209, 201, 0.28);
        align-self: center;
        min-height: 180px;
      }
      .img {
        background: none;
        flex: 1;
        &.ecg {
          width: 510px;
        }
        &.ecg1 {
          height: 295px;
          position: relative;
          img {
            position: absolute;
            top: 0;
            left: 90px;
          }
        }
      }
    }
  }
}
</style>
